import React, { Component } from "react";
import { Grid, Image } from "semantic-ui-react";
import icon from "../images/jgglogo_202102.svg";
import { connect } from "react-redux";
import PackagePurchaseForm from "../components/forms/package-purchase-form/package-purchase-form";
import { rejectIfAnyNull } from "../controllers/utilities/env-checks";
import iconGg from "../images/gg-logo.png";
import { environmentMode } from "../components/lib/ad-manage-api-lib";
import "../styles/bg.css";
import { navigate } from "gatsby";
import NavigationBar from "../components/forms/navigation-bar";

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings
    };
})(class extends Component {
    constructor(props, context) {
        super(props, context);
        // rejectIfAnyNull(this.props.session);
    }

    componentDidMount() {
        window.addEventListener('resize', this.getDirection);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.getDirection);
    }
    getDirection = () => {
        this.setState({ state: this.state });
    }

    onCancel() {
        navigate("/confirm-purchase");
    }

    render() {
        const mode = detectOrient();
        if (typeof window !== `undefined`) {
            var docEl = document.documentElement;
            var clientWidth = docEl.clientWidth;
            if (!clientWidth) return;
            if (mode === "PORTRAIT"){
                docEl.style.fontSize = 14 * (clientWidth / 450) + 'px';
            }else{
                docEl.style.fontSize = 14 * (clientWidth / 900) + 'px';
            }              
        }
        let iconJgg = icon;
        if (environmentMode === "SANDBOX") iconJgg = iconGg;
        return (
            <React.Fragment>        
                <NavigationBar mode={mode} platform={"h5"} showLogo={1} showTitle={0} onBack={this.onCancel.bind(this)}/>

                <Grid textAlign='center' style={{
                    margin: '0',
                    width: '100%',
                    minHeight: "100vh",
                    paddingTop: "2vh",
                }} verticalAlign='top'>
                    {
                        (mode === "PORTRAIT") && (
                            <Grid.Column style={{maxWidth: 450, paddingTop:"80px"}}>
                                
                                <PackagePurchaseForm mode="PORTRAIT" />
                            </Grid.Column>)
                    }
                    {
                        (mode === "LANDSCAPE") && (
                            <Grid.Column style={{paddingTop: "80px", width:"90%"}}>                                
                                <PackagePurchaseForm mode="LANDSCAPE" />
                            </Grid.Column>
                        )
                    }
                </Grid>
            </React.Fragment>
        );
    }
});

function detectOrient() {
    if (typeof window !== `undefined`) {
        // let isIOS = "0";
        // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
        //     // 暂时去掉ios两板功能
        //     isIOS = "1";
        // }
        // if (isIOS === "0") return "PORTRAIT";
        if (window.innerHeight >= window.innerWidth) {
            // 竖屏
            // console.log("竖屏");
            return "PORTRAIT";
        } else {
            // 横屏
            // console.log("横屏");
            return "LANDSCAPE";
        }
    } else {
        return "PORTRAIT";
    }
}